// src/components/Modal.js
import React, { useEffect, useState } from 'react';
import '../../css/wtpstyle.css'; // For basic styling
import axios from 'axios';
import Backend_Url from '../../config/config';
import { useCookies } from 'react-cookie';
import { Icon } from '@iconify/react/dist/iconify.js';
import Swal from 'sweetalert2';

const MemberModal = ({ isOpen, onClose, wa_id, totalmembers }) => {
    const [cookies, setcookies, removecookies] = useCookies(['token'])
    const [result, setResult] = useState([])
    const [currentPage, setcurrentPage] = useState(0)
    const [loader, setloader] = useState(true)
    const [pageNumber, setpageNumber] = useState(1)
    const [searchname, setsearchname] = useState("")
    const [filter, setfilter] = useState([])

    useEffect(() => { fetchdata() }, [])


    const fetchdata = async (pageNumber = 1, pageSize = 50) => {
        try {
            debugger
            const response = await axios.get(`${Backend_Url}/contactgroups/${wa_id}/contacts`,
                {
                    params: {
                        pageSize: pageSize,
                        pageNumber: pageNumber,
                        Filter: JSON.stringify(filter)
                    },
                    headers: {
                        Authorization: `Bearer ${cookies?.token}`,
                    },
                }
            )



            const result = await response.data
            console.log("members result", result);

            if (searchname.length > 0) {
                setResult(result.contacts)
            }
            if (pageNumber <= result.totalpages && result.contacts.length !== 0) {
                setResult((prev) => {
                    const existingIds = new Set(prev.map(contact => contact.wa_id)); // Assuming 'id' is the unique identifier
                    const newContacts = result.contacts.filter(contact => !existingIds.has(contact.wa_id));
                    return [...prev, ...newContacts];
                });
            }
            setloader(false)
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        console.log("result", result);
    }, [result]);

    useEffect(() => {
        fetchdata(pageNumber, 50)
        console.log('result ', result);

    }, [pageNumber, searchname])


    if (!isOpen) return null;


    const handleDelete = async (member, gruop_wa_id) => {

        if (!cookies.token) {
            console.error('No token found');
            return;
        }
        debugger
        const result = await Swal.fire({
            title: 'Are you sure delete this contact?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        });

        if (result.isDismissed) {
            return
        }

        try {
            ///contactgroups/delete/contactgruopcontact/9999999999/D8uLKuPokdbWerteMFLIQ
            const response = await axios.delete(`${Backend_Url}/contactgroups/delete/contactgruopcontact/${member.wa_id}/${gruop_wa_id}`, {
                headers: {
                    Authorization: `Bearer ${cookies?.token}`
                },
            });

            if (response.status == 200) {
                Swal.fire(
                    'Deleted!',
                    'Your contact has been deleted.',
                    'success'
                );

                fetchdata()
            }
        } catch (error) {
            console.log(error)
            Swal.fire(
                'Error!',
                'There was a problem deleting the contact.',
                'error'
            );
        }
    }


    // -------------------------------pagination-------------------------------------



    const handlescroll = async (e) => {
        try {
            debugger
            const target = e.target;
            const scrollPosition = target.scrollTop;
            const scrollHeight = target.scrollHeight;
            const clientHeight = target.clientHeight;

            // Check if we're within 100 pixels of the bottom
            if (scrollPosition + clientHeight >= scrollHeight - 50) {
                setpageNumber((prev) => prev + 1)
            }
        } catch (error) {
            console.error("Error in HandleScroll:", error);
        }
    }


    const handleFilter = async (e) => {
        debugger
        // setsearchvalue(e.target.value)

        if (e.target.value.length > 0) {
            // setApiData((prev) => ({
            //     ...prev,
            //     contacts: prev.contacts.filter((item) => item.wa_id?.includes(e.target.value)),
            // }))
            setsearchname(e.target.value)
            const { value } = e.target
            setfilter([{ Profile: value }])
        }

        if (e.target.value.length == 0) {
            // fetchData()
            setsearchname("")
            setfilter([])
            fetchdata(pageNumber, 50)
        }
    }



    return (
        <div className="modal-overlay-members">
            <div className="modal-content-members">
                <button className="close-button-members" onClick={() => onClose(false)}>X</button>
                <h2>Member List</h2>
                <div className='Input-Box-Compaigns'>
                    <input className="search-input-contact" onChange={(e) => handleFilter(e)} type="text" name="" placeholder="Search" />
                </div>
                <ul className={`members-ul`} onScroll={handlescroll} style={{ listStyle: "none", padding: "0" }}>

                    {result.map((member, index) => (
                        member.Profile != ' ' ? (
                            <li
                                style={{ display: "flex", alignItems: "start", justifyContent: "space-between", padding: "5px 0", borderBottom: "1px solid", borderBottomColor: "gray" }}
                                key={index}
                            >
                                <span
                                    style={{
                                        display: "flex",
                                        alignItems: "flex-start",
                                        gap: "3px",

                                        padding: "5px 0" // Optional: adds some padding
                                    }}
                                >
                                    <span
                                        style={{
                                            display: "inline-block",
                                            backgroundColor: "#e0e0e0", // Light gray background
                                            color: "#333", // Dark text color
                                            borderRadius: "4px",
                                            padding: "5px 10px",
                                            fontWeight: "bold",
                                            fontSize: "14px", // Font size
                                            marginRight: "5px", // Space between count and next element
                                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)" // Subtle shadow
                                        }}
                                    >
                                        {`${index + 1})`}
                                    </span>
                                    {member.Profile}
                                </span>
                                <button onClick={() => handleDelete(member, wa_id)} style={{ backgroundColor: "transparent" }} className={`dlt-btn`}>
                                    <span className={`delete-button`}>
                                        <Icon icon="fluent:delete-12-filled" />
                                    </span>
                                </button>
                            </li>
                        ) :

                            <li
                                style={{ display: "flex", alignItems: "start", justifyContent: "space-between", padding: "5px 0", borderBottom: "1px solid", borderBottomColor: "gray" }}
                                key={index}
                            >
                                <span style={{ display: "flex", alignItems: "start", gap: "3px", borderBottomColor: "gray" }} >
                                    <span
                                        style={{
                                            display: "inline-block",
                                            backgroundColor: "#e0e0e0", // Light gray background
                                            color: "#333", // Dark text color
                                            borderRadius: "4px",
                                            padding: "5px 10px",
                                            fontWeight: "bold",
                                            fontSize: "14px", // Font size
                                            marginRight: "5px", // Space between count and next element
                                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)" // Subtle shadow
                                        }}
                                    >
                                        {`${index + 1})`}
                                    </span>
                                    {member.wa_id}
                                </span>
                                <button onClick={() => handleDelete(member, wa_id)} style={{ backgroundColor: "transparent" }} className={`dlt-btn`}>
                                    <span className={`delete-button`}>
                                        <Icon icon="fluent:delete-12-filled" />
                                    </span>
                                </button>
                            </li>

                    ))}

                    {
                        result.length == 0 && loader ? <li>loading...</li> : result.length == 0 && <li>No members</li>
                    }

                </ul>

                {/* -------------------------create pagination btn --------------------------*/}
                <div className="pagination-controls">
                    <button
                        onClick={() => setcurrentPage(currentPage - 50)}
                        disabled={currentPage == 0}
                    >
                        <Icon icon="ooui:arrow-next-rtl" width="12" height="12" style={{ color: "black" }} />

                    </button>
                    <span>contact {result.length} of {totalmembers}</span>
                    <button
                        onClick={() => setcurrentPage(currentPage + 50)}
                        disabled={result.length < 50}
                    >
                        <Icon icon="ooui:arrow-next-ltr" width="12" height="12" style={{ color: "black" }} />
                    </button>
                </div>
            </div>
            <div onClick={() => onClose(false)} className={`close-members`}></div>
        </div>
    );
};


export default MemberModal;
